import "./App.css";
//images and css
import img0 from "./images/0.png"
import img1 from "./images/1.png"
import img2 from "./images/2.png"
import img3 from "./images/3.png"
import img4 from "./images/4.png"
import img5 from "./images/5.png"
import img6 from "./images/6.png"
import img7 from "./images/7.png"
import img8 from "./images/8.png"
import img9 from "./images/pharaoh.png"
import img10 from "./images/xray.png"
import logo from "./images/logo.png"
import sg from "./images/sg0.png"
import bg from "./images/bg.png"
import bonkmap from "./images/bonkmap.png"
import "./Home.css"
import dc from "./images/dc.png"
import tg from "./images/tg.png"
import tw from "./images/tw.png"
import web from "./images/web.png"
import lt from "./images/lt.png"
//react router dom
import {
  BrowserRouter,
  Link,
  Route,
  Routes
} from "react-router-dom"

import { useMemo } from "react";
import * as anchor from "@project-serum/anchor";
import Home from "./Home";
import { DEFAULT_TIMEOUT } from "./connection";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";

import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";

import { createTheme, ThemeProvider } from "@mui/material";
import { PhantomWalletAdapter } from "@solana/wallet-adapter-phantom";
import { SlopeWalletAdapter } from "@solana/wallet-adapter-slope";
import { SolflareWalletAdapter } from "@solana/wallet-adapter-solflare";
import {
  SolletWalletAdapter,
  SolletExtensionWalletAdapter,
} from "@solana/wallet-adapter-sollet";
import Marquee from "react-fast-marquee";
const theme = createTheme({
  palette: {
    mode: "dark",
  },
});

const getCandyMachineId = (): anchor.web3.PublicKey | undefined => {
  try {
    return new anchor.web3.PublicKey(process.env.REACT_APP_CANDY_MACHINE_ID!);
  } catch (e) {
    console.log("Failed to construct CandyMachineId", e);
    return undefined;
  }
};

let error: string | undefined = undefined;

if (process.env.REACT_APP_SOLANA_NETWORK === undefined) {
  error =
    "Your REACT_APP_SOLANA_NETWORK value in the .env file doesn't look right! The options are devnet and mainnet-beta!";
} else if (process.env.REACT_APP_SOLANA_RPC_HOST === undefined) {
  error =
    "Your REACT_APP_SOLANA_RPC_HOST value in the .env file doesn't look right! Make sure you enter it in as a plain-text url (i.e., https://metaplex.devnet.rpcpool.com/)";
}

const candyMachineId = getCandyMachineId();
const network = (process.env.REACT_APP_SOLANA_NETWORK ??
  "devnet") as WalletAdapterNetwork;
const rpcHost =
  process.env.REACT_APP_SOLANA_RPC_HOST ?? anchor.web3.clusterApiUrl("devnet");
const connection = new anchor.web3.Connection(rpcHost);

const App = () => {
  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SolflareWalletAdapter({ network }),
      new SlopeWalletAdapter(),
      new SolletWalletAdapter({ network }),
      new SolletExtensionWalletAdapter({ network }),
    ],
    []
  );

  async function HandleDropdown(){
    const status = document.getElementById("DropdownMenu")?.style.getPropertyValue("display")
    if(status === ""){
      document.getElementById("DropdownMenu")?.style.setProperty("display","grid")
      document.getElementById("root")?.addEventListener("click", HandleClose)
      return;
    } 
    if(status === "grid") document.getElementById("DropdownMenu")?.style.setProperty("display","")
  }

  async function HandleClose(){
    document.getElementById("DropdownMenu")?.style.setProperty("display","")
    document.getElementById("root")?.removeEventListener("click", HandleClose)
  }

  return (
    <div style={{display:"grid"}}>
<BrowserRouter>
    <img id="Background" src={bg} alt="Background"/>
    <div style={{display:"grid"}}>
    <Link to="/" style={{textAlign: "center", width:"fit-content", justifySelf:"center"}}>
    <img id="LOGO" src={logo} alt="LOGO" style={{marginTop:"20px", width:"350px"}}/>
    </Link>
    <div id="Menu">
      <Link to="/mint">Mint</Link>
      <a href="https://stake.shibonkcoin.com/">Staking</a>
      <a href="https://moonrank.app/collection/shibonk_gang">Rarity</a>
      <a href="https://shibonkcoin.com">Shibonk</a>
      <div style={{display:"grid"}}>
      <a href="#" onClick={HandleDropdown}>Marketplaces</a>
      <div id="DropdownMenu">
          <a href="https://opensea.io/collection/shibonk-gang">OpenSea</a>
          <a href="https://magiceden.io/marketplace/6xSm3wN3aU2bApS3mjbj9r2dZbNYe9K1E1E3E96tp9pi">MagicEden</a>
        </div>
      </div>
      <Link to="/bonkmap">Bonkmap</Link>  
    </div>
    
    </div>
    <Routes>
      <Route path="/mint" element={<ThemeProvider theme={theme}>
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect>
          <WalletDialogProvider>
            <div style={{display:"grid"}}>
            <h3 style={{color:"honeydew", textAlign:"center"}}>Mint Your Shibonk Gang NFT!</h3>
    <Marquee gradient={false} speed={70} style={{width:"80%", maxWidth:"950px", overflowY:"hidden"}} className="NFTcontainer">
      <img src={img0} alt="NFT"/>
      <img src={img1} alt="NFT"/>
      <img src={img2} alt="NFT"/>
      <img src={img3} alt="NFT"/>
      <img src={img9} alt="NFT"/>
      <img src={img4} alt="NFT"/>
      <img src={img5} alt="NFT"/>
      <img src={img6} alt="NFT"/>
      <img src={img7} alt="NFT"/>
      <img src={img8} alt="NFT"/>
      <img src={img10} alt="NFT"/>
    </Marquee>
    <Home candyMachineId={candyMachineId}
              connection={connection}
              txTimeout={DEFAULT_TIMEOUT}
              rpcHost={rpcHost}
              network={network}
              error={error}/>
            </div>
            
          </WalletDialogProvider>
        </WalletProvider>
      </ConnectionProvider>
    </ThemeProvider>}/>
      <Route path="/" element={<Hero/>}/>
      <Route path="/bonkmap" element={<Roadmap/>}/>
      <Route path="/rarity" element={<Rarity/>}/>
    </Routes>
    <div id="Footer">
      <a href="https://t.me/SHIBONK_SOL"><img src={tg} alt="tg"/></a>
      <a href="https://discord.gg/shibonk"><img src={dc} alt="dc"/></a>
      <a href="https://shibonkcoin.com"><img src={web} alt="web"/></a>
      <a href="https://twitter.com/SHIBONKSOL"><img src={tw} alt="tw"/></a>
      <a href="https://linktr.ee/shibonksol"><img src={lt} alt="lt"/></a>
    </div>
    </BrowserRouter>

    </div>
  );
};

function Hero(){
  return(
  <div id="Hero">
    <span style={{fontSize:"2em"}}>Meet the Shibonk Gang!</span>
    <div id="HeroContainer">
      <div id="HeroLeft">
        <span>
        Shibonk Gang is an NFT collection of 4,200 unique and randomly generated artworks converted into smart contract code and deployed on Blockchain forever.<br/><br/>
Shibonk Gang or “SG” aims to provide the Shibonk Community with a collection that can be correlated with Social Status, to which people self-identify themselves with the artwork, and hold sentimental value.<br/><br/>
The collection is made up of 4200 Unique Shiba Inu Dogs crossed between Human traits and hundreds of different Styles, colours, and themes.
        </span>
        <Link id="FakeButton" style={{textDecoration:"none", padding:"10px 20px", width:"fit-content", borderRadius:"10px"}} to="/mint">Mint Your NFT</Link>
      </div>
      <div id="HeroRight">
        <img src={sg} alt="SG"/>
      </div>
    </div>
  </div>
  )
}

function Roadmap(){
  return(
    <div id="Roadmap">
      <img id="Bonkmap" src={bonkmap} alt="BONKMAP"/>
    </div>
  )
}

function Rarity(){
  return(
    <div id="Rarity">
      <h1>Coming Soon...</h1>
    </div>
  )
}

export default App;
